.modal {
  width: 95%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 15%);
  border-radius: 0.2rem;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 3.2rem;
}

@media (min-width: 768px) {
  .modal {
    width: 48rem;
  }
}

.fullwidth {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translate(0, 0);

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
