.list {
  display: flex;
  padding-left: 0;
  position: relative;
  justify-content: flex-start;
  overflow: auto;
  margin-bottom: -4.8rem;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    overflow: visible;
    justify-content: center;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.item {
  margin: 0.5rem 1rem;
}

.item-reduced {
  @media (max-width: 768px) {
    max-width: calc(100% / 2 - 0.5rem);
    margin: 0.5rem 0.4rem;
  }
}

.item-hidden {
  display: none;
}

.item-visible {
  display: block;
}

.button {
  padding: 0.8rem 2rem 0.7rem;
  font-size: 1.4rem;
  line-height: 1.2;
  white-space: nowrap;
  border: 0.1rem solid #ccc;
  border-radius: 4.8rem;
  cursor: pointer;
  transition: 0.3s;

  @media (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button-default {
  color: #4ba1a5;
  background-color: #fefefe;
  border-color: #eee;
  box-shadow:
    0 0.3rem 0.8rem rgb(0 0 0 / 12%),
    0 0.3rem 0.1rem rgb(0 0 0 / 4%);

  &:hover {
    color: #fefefe;
    border-color: rgb(75 161 165);
    background-color: rgb(75 161 165);
  }
}

.button-close {
  position: absolute;
  top: -1.6rem;
  right: 0;
  border: none;
  outline: none;
  background: transparent;
}

.button-active {
  border-color: #4ba1a5;
  background-color: #4ba1a5;
  color: #fefefe;
  cursor: default;
}
