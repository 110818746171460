.list {
  margin: 0 -0.6rem !important;
  padding: 0 !important;

  @media (min-width: 768px) {
    margin: 0 -1.2rem !important;
  }
}

.item {
  width: calc(100% / 1 - 2.4rem);
  margin: 0 1.2rem;
  background: #fff;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;
  transition: 0.3s;
  margin-bottom: 2.4rem;

  &:hover {
    box-shadow: 0 0 2rem #ddd;
    transition: 0.3s;
  }

  @media (min-width: 768px) {
    width: calc(100% / 2 - 2.4rem);
  }

  @media (min-width: 992px) {
    width: calc(100% / 3 - 2.4rem);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 4 - 2.4rem);
  }
}

.title {
  font-size: 2rem;
  color: #254e5f;
  margin-bottom: 2.4rem;
  font-weight: 700;
  font-family: 'Nunito Sans';
  line-height: 2.4rem;
}

.no-coupons-title {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 600;
  font-family: 'Nunito Sans';
  color: #254e5f;
}

.mt-4 {
  margin-top: 2.4rem;
}

.no-result-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}

.no-result-desc {
  p {
    margin-top: 1rem !important;
    margin-bottom: 2.4rem !important;
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #333;
    font-weight: 400;
  }
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}

.logo {
  margin-bottom: 2.4rem;
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 3.2rem;
  }
}

.no-result-main {
  margin-top: 4.2rem;

  @media (min-width: 768px) {
    margin-top: 8.2rem;
  }
}
