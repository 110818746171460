.wrap {
  font-family: 'Nunito Sans', sans-serif;

  a {
    color: #4ba1a5;

    &:hover {
      color: #40898c;
    }
  }
}

.main {
  @media (min-width: 768px) {
    display: flex;
  }
}

.block-wrap {
  background: #fff;
  padding: 2.4rem 0;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;

  @media (min-width: 768px) {
    width: 35.7rem;
  }
}

.block-info {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-right: 3.2rem;
  }
}

.block-wrap-desc {
  width: 100%;
}

.block-desc {
  white-space: pre-line;
  flex: 1;
  background: #fff;
  padding: 2.4rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;
  margin-bottom: 3.2rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.pic {
  max-width: 35rem;
  margin: 0 auto;
  position: relative;

  img {
    border-radius: 4px 4px 0 0;
    width: 100%;
  }
}

.divider {
  margin: 1rem 0;
}

.pic-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 7%);
  border-radius: 0.4rem 0.4rem 0 0;
}

.text_block {
  max-width: 30.9rem;
  margin: 0 auto;
}

.title {
  font-size: 2rem;
  color: #333;
  line-height: 2.4rem;
  font-weight: 700;
  margin-top: 1.6rem;
  font-family: 'Nunito Sans', sans-serif;
}

.shop-name {
  text-align: center;
  margin-top: 1.4rem;
  color: #c7c7cc;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;
  overflow: hidden;
  padding-left: 2.4rem !important;
  padding-right: 2.4rem !important;
}

.desc {
  font-size: 1.6rem;
  margin-top: 2rem;
  color: #333;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  white-space: pre-line;
  position: relative;
  overflow: hidden;
}

.btn {
  font-family: 'Nunito Sans';
  font-weight: 600;
  width: 100%;
  border: none;
  font-size: 1.6rem;
  line-height: 1.8rem;
  padding: 1.5rem 2.2rem;
  text-align: center;
  background: linear-gradient(135deg, #3c9f83 0%, #4ba1a5 100%);
  border-radius: 0.8rem;
  cursor: pointer;
  color: #fff;

  &:hover {
    background: linear-gradient(135deg, #40898c 0%, #40898c 100%);
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 7%);
  }
}

.img {
  max-width: 15.5rem;
  height: 5rem;
  margin: auto;

  img {
    object-fit: contain;
    margin: auto;
  }
}

.square_pic {
  height: 15rem;
}

.subtitle {
  font-weight: 700;
  font-size: 2rem !important;
  line-height: 2.4rem;
  color: #254e5f;
}

.list {
  & li {
    margin-bottom: 0.4rem;
    list-style: disc !important;
  }
}

.deadline {
  margin-top: 1rem;
  font-family: Roboto;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #adadb8;
}

.deadline_error {
  color: #d12e34;
}

.deadline_warning {
  color: #dcae1d;
}

.promo {
  background: linear-gradient(135deg, #3c9f83 0%, #4ba1a5 100%);
  color: #fff;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.05);
  border-radius: 0.8rem;
  position: relative;
  height: 5.6rem;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &::before {
    border: 0.1rem solid #d6d6d6;
    border-left: none;
    border-radius: 0.8rem;
    width: 3rem;
    height: 100%;
    transition: all 0.3s ease;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    background-color: #fff;
  }

  &::after {
    position: absolute;
    background-image: url('../images/shtorka.svg');
    width: 4rem;
    height: 7rem;
    content: '';
    bottom: 0;
    right: 0;
    transition: all 0.3s ease;
    background-repeat: no-repeat;
  }

  &:hover {
    transition: 0.3s;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.07);

    &::before {
      width: 4rem;
    }

    &::after {
      width: 5.6rem;
    }
  }
}

.date {
  font-size: 1rem;
  line-height: 1.8rem;
}

.error {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: red;
}

.detail {
  color: #333333 !important;
  font-family: 'Roboto' !important;
  font-size: 1.6rem !important;
  line-height: 2rem !important;
  font-weight: 400 !important;
  div,
  span,
  p,
  b {
    color: #333333 !important;
    font-family: 'Roboto' !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    font-weight: 400 !important;
  }

  div {
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.promocode-wrapper {
  position: relative;
  display: flex;
  min-width: 25rem;
  margin: 2.5rem auto;
  background-color: #fff;
  border-radius: 0.8rem;
  border: 1px solid #d6d6d6;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.07);

  &::before {
    border-right: none;
    border-radius: 0.8rem;
    width: 2rem;
    height: 100%;
    transition: all 0.3s ease;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(135deg, #3c9f83 0%, #4ba1a5 100%);
  }

  &::after {
    position: absolute;
    background-image: url('../images/shtorka.svg');
    width: 4rem;
    height: 6.9rem;
    content: '';
    bottom: 0;
    left: 0.6rem;
    transition: all 0.3s ease;
    background-repeat: no-repeat;
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
}

.left_divider,
.right_divider {
  width: 0.8rem;
  overflow: hidden;

  div {
    width: 1.6rem;
    height: 1.6rem;
    background-color: #f8f8f8;
    border-radius: 50%;
  }
}

.left_divider {
  margin-right: 0.6rem;
  transform: rotateY(180deg);
}

.middle_divider {
  border-bottom: 6px dotted rgba(0, 0, 0, 0.05);
  width: 100%;
}

.right_divider {
  margin-left: 0.6rem;
}

.promocode {
  width: 100%;
  color: #254e5f;
  padding: 1.9rem 0 1.9rem 3rem;
  font-weight: 600;
  box-shadow: none;
  outline: 0;
  font-size: 1.6rem;
  line-height: 1.8rem;
  border: none;
  background-color: transparent;
  text-align: center;
}

.clipboard-success {
  align-self: center;
  width: 4.5rem;
  height: 4.5rem;
  background: url('../images/icons/check.webp') no-repeat center;
}

.copy-btn {
  align-self: center;
  width: 4.5rem;
  height: 4.5rem;
  border: none;
  background: transparent;
  padding: 1.4rem;
}

.copy-btn_wrap {
  display: flex;
  align-items: center;
}

.coupon_list {
  margin: 0 -0.6rem !important;
  padding: 0 !important;

  @media (min-width: 768px) {
    margin: 0 -1.6rem !important;
  }
}

.coupon_item {
  list-style: none !important;
  width: calc(100% / 1 - 3.2rem);
  margin: 0 1.6rem;
  background: #fff;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;
  transition: 0.3s;
  margin-bottom: 2.4rem;

  &:hover {
    box-shadow: 0 0 2rem #ddd;
    transition: 0.3s;
  }

  @media (min-width: 992px) {
    width: calc(100% / 2 - 3.2rem);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 3 - 3.2rem);
  }
}

.desc_divider {
  margin: 2.4rem 0;
  height: 0.2rem;
  border-radius: 0.8rem;
  background: rgba(0, 0, 0, 0.05);
}
