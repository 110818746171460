@import './styles/font-faces';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body,
#root,
.page {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8 !important;
  font-size: 1.6rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

a,
h1,
h2,
h3,
ul,
li,
button,
input {
  margin: 0;
  padding: 0;
}

ul > li,
ol > li {
  list-style: none;
}

a {
  text-decoration: none !important;
}

img {
  display: block;
  max-width: 100%;
  height: 100%;
}

a.link {
  color: #4ba1a5;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #111;
    transition: 0.3s;
  }
}
