.label {
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.input {
  border-radius: 0.4rem;
  color: #444;
  background-color: #fff !important;
  border: 0.1rem solid #ccc;
  font-weight: 400;
  width: 100%;
  padding: 1.2rem 1rem;
  height: auto;
  font-size: 1.4rem;
  max-width: 100%;
}

.link {
  font-size: 1.4rem;
  color: #4ba1a5;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #111;
    transition: 0.3s;
  }
}

.tooltip {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem !important;
}

.btn {
  margin-top: 0.8rem;
  color: #fff;
  outline: none;
  border-radius: 0.4rem;
  padding: 1rem 1.8rem;
  background: #4ba1a5;
  border: 0.1rem solid #4ba1a5;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #254e5f;
    border: 0.1rem solid #254e5f;
    transition: 0.3s;
  }

  &:disabled {
    background-color: #9a9a9a;
    border: 0.1rem solid #9a9a9a;
  }
}

.check-label {
  margin-left: 0.8rem;
  cursor: pointer;
}

.error-text {
  position: absolute;
  padding-left: 1.6rem;
  padding-top: 0.4rem;
  color: #d12e34;
  font-size: 1.2rem;
  margin-bottom: 0;
  bottom: -1.6rem;
}

.info-text {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.info-text-warning {
  color: #dcae1d;
}

.info-text-error {
  color: #d12e34;

  a {
    font-size: 1.2rem;
  }
}

.progress {
  position: absolute;
  top: 0.8rem;
  left: 0;
  width: 100%;
  height: 4.8rem;
  background-image: linear-gradient(
    45deg,
    rgb(255 255 255 / 15%) 25%,
    transparent 25%,
    transparent 50%,
    rgb(255 255 255 / 15%) 50%,
    rgb(255 255 255 / 15%) 75%,
    transparent 75%,
    transparent
  );
  background-size: 4rem 4rem;
  background-color: #4ba1a5;
  transition: width 0.6s ease;
  animation: progress-bar-stripes 2s linear infinite;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 4rem 0;
  }
}

.mt-2 {
  margin-top: 0.8rem;
}

.mt-3 {
  margin-top: 1.6rem;
}
