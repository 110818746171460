.banner {
  border-radius: 4px;
  padding: 3.2rem;
  margin-bottom: 3.2rem;
  margin-top: 3.2rem;
  display: none;
  white-space: pre-line;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 992px) {
    padding: 2.4rem 3.2rem;
    white-space: normal;
  }
}

.banner.ofd {
  background: linear-gradient(
    45deg,
    rgb(60, 159, 131) 0%,
    rgb(69, 160, 151) 61.638%,
    rgb(75, 161, 165) 100%
  );
}

.banner.default {
  border: 1px solid #d6d6d6;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 9rem;

  @media (min-width: 992px) {
    height: 20.4rem;
  }

  @media (min-width: 1200px) {
    height: 19.7rem;
  }
}

.banner_wrap {
  display: grid;
  grid-template-areas:
    'info icon'
    'links icon';
  @media (min-width: 992px) {
    grid-template-areas: 'info icon links';
  }
}

.info {
  grid-area: info;

  @media (min-width: 992px) {
    width: 24.4rem;
  }

  @media (min-width: 1200px) {
    width: auto;
  }
}

.icon {
  width: 30.5rem;
  grid-area: icon;
  position: relative;

  @media (min-width: 992px) {
    width: 34.5rem;
  }

  @media (min-width: 1200px) {
    width: 34.1rem;
    margin-right: 2.4rem;
  }
}

.icon_wrap {
  position: absolute;
  height: 40.8rem;
  top: -7.3rem;
  right: -7%;

  @media (min-width: 992px) {
    top: -4rem;
    height: auto;
    right: auto;
  }

  @media (min-width: 1200px) {
    top: -4.4rem;
  }
}

.title.ofd,
.subtitle.ofd {
  color: #fff;
}

.title.default {
  color: #333333;
}

.subtitle.default {
  color: #adadb8;
}

.title {
  font-family: 'Nunito Sans';
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.3rem;
}

.subtitle {
  font-family: 'Nunito Sans';
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 1.6rem;
}

.qr_code {
  border-radius: 0.2rem;
  box-shadow: 0 0.1rem 0.18rem 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0.4rem;
  margin-right: 2.4rem;

  @media (min-width: 1200px) {
    margin-right: 3.2rem;
  }
}

.desc.ofd {
  color: #c7e1da;
}

.desc.default {
  color: #adadb8;
}

.desc {
  font-family: 'Roboto';
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.links {
  margin-top: 2rem;

  @media (min-width: 992px) {
    margin-top: 1.6rem;
  }

  @media (min-width: 1200px) {
    display: flex;
  }

  & div:first-child {
    margin-bottom: 1.6rem;

    @media (min-width: 1200px) {
      margin-bottom: 0;
      margin-right: 1.6rem;
    }
  }
}

.links_block {
  grid-area: links;
  display: flex;
  align-items: flex-end;
  margin-top: 3.2rem;

  @media (min-width: 992px) {
    margin-top: 0;
  }
}
