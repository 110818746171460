.search {
  position: relative;

  @media (min-width: 768px) {
    max-width: 42.8rem;
  }
}

.fullwidth {
  max-width: 100%;
}

.search-icon {
  margin: 0 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
}

.clear-text-btn {
  position: absolute;
  right: 5rem;
  z-index: 1000;

  svg {
    stroke: #ffffff;
  }
}

.input {
  background: rgb(0 0 0 / 5%);
  border: 0.2rem solid #40898c;
  box-shadow: inset 0 0.1rem 0.4rem rgb(0 0 0 / 5%);
  border-radius: 6rem;
  transition: 0.3s;
  width: 100%;
  height: 4.8rem;
  outline: none;
  padding-right: 6.4rem;
  padding-left: 2rem;
  padding-bottom: 0.4rem;
  display: none;
  color: #fff;

  &::placeholder {
    color: #fff;
  }

  &:hover {
    transition: 0.3s;
    box-shadow: 0 0 0.5rem #eee;
  }

  &:focus {
    background: #fff;
    color: #254e5f;

    & + .clear-text-btn {
      svg {
        stroke: #adadb8;
      }
    }
  }

  @media (min-width: 768px) {
    display: block;
  }
}

.open-mob-search {
  display: block;
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  padding: 0 0.8rem;
  height: 4.8rem;
  width: 4.8rem;
  background: linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
  border: 0.2rem solid #fff;
  transition: 0.3s;
  box-shadow: 0 0.1rem 0.4rem rgb(0 0 0 / 5%);
  border-radius: 4.8rem;
  z-index: 99;

  & img {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover {
    background: #40898c;
    box-shadow: 0 0.1rem 0.4rem rgb(0 0 0 / 5%);
    transition: 0.3s;
  }

  &:focus-visible {
    border: 0.2rem solid #5bc0de;
    background: #4ba1a5;
  }
}

.result {
  position: absolute;
  width: 100%;
  margin: -1rem 2rem 0;
  z-index: 999999;
  left: 0;
  top: 130%;
  box-shadow: 0 0.1rem 0.5rem rgb(0 0 0 / 15%);

  @media (max-width: 768px) {
    position: fixed;
    z-index: 100;
    top: 8rem;
    left: 0;
    width: 100%;
    margin: 0;
  }

  @media (min-width: 768px) {
    width: 75rem;
    left: -60%;
  }

  @media (min-width: 1024px) {
    left: -6rem;
  }
}

.result-list {
  background: #fff;
  overflow: auto;
  margin: 0;
  padding: 0;
  max-height: 30rem;
  box-shadow: 0.3rem 0.3rem 1.6rem rgba(112, 112, 124, 0.2);
  border-radius: 0px 0px 8px 8px;
  @media (min-width: 768px) {
    border-radius: 0.8rem;
    max-height: 38rem;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.result-item {
  padding: 1.6rem;
  border-bottom: 0.1rem solid #eee;

  &:last-child {
    border: none;
  }

  &:hover {
    background: #f2f2f2;
    box-shadow: inset 0 0.1rem 0.4rem rgba(0, 0, 0, 0.05);

    & span {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #f2f2f2 100%
      );
    }
  }
}

.form {
  position: relative;
}

.none-result {
  padding: 1.6rem !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 8rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 99;
}

.banner-active {
  top: 13rem;
}

.result-total-btn {
  width: 100%;

  button {
    border-radius: 0 0 0.8rem 0.8rem;
  }
}

.result-list--mob-height {
  @media (max-width: 480px) {
    max-height: 26rem;
  }
}
