.footer {
  box-shadow: inset 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  background: rgb(238, 238, 238);
  padding: 3.2rem 0;
}

.footer_top,
.footer_banner,
.bottom_links,
.banner_desc,
.footer_desc {
  @media (min-width: 768px) {
    display: flex;
  }
}

.banner_desc,
.footer_desc {
  flex-direction: column;
}

.bottom_links {
  @media (min-width: 768px) {
    align-items: center;
  }
}

.footer_banner {
  margin-top: 3.2rem;
  @media (min-width: 768px) {
    margin-top: 0;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);
    padding: 2.4rem;
  }
}

.links {
  display: flex;

  & > div {
    &:first-child {
      margin-right: 0.8rem;
    }
  }
}

.footer_top,
.footer_banner,
.banner_desc,
.footer_desc {
  justify-content: space-between;
}

.banner_desc {
  @media (min-width: 768px) {
    width: 28.2rem;
  }
}

.footer_desc {
  & p {
    margin-top: 1.2rem;

    @media (min-width: 768px) {
      margin-top: 2.4rem;
    }
  }
}

.bottom {
  color: rgb(133, 133, 148);
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 3.2rem;
}

.bottom_links {
  justify-content: space-between;
  @media (min-width: 992px) {
    justify-content: inherit;
  }

  & > div {
    margin-top: 1.6rem;
    @media (min-width: 992px) {
      margin-right: 4.8rem;
    }
  }

  & a {
    text-decoration-line: underline !important;
  }
}

.qr_code {
  border-radius: 0.2rem;
  box-shadow: 0 0.1rem 0.18rem 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0.4rem;
  margin-left: 2.4rem;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.banner_title {
  font-family: Nunito Sans;
  font-size: 2.2rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 0.8rem;
  }
}

.banner_text {
  display: none;
  color: rgb(133, 133, 148);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    display: block;
  }
}

.footer_btn {
  border: none !important;
  margin-top: 1.6rem;
  border-radius: 8px !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05) !important;
  width: fit-content !important;
}

.link {
  color: rgb(133, 133, 148);
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
}
