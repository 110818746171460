.header {
  background: linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 15%);
  padding: 1.6rem 0;
  flex: 0;
  font-size: 1.6rem;
  @media (min-width: 768px) {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
}

.wrap {
  display: flex;

  & > div {
    @media (min-width: 480px) {
      flex: 2;
    }

    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 1;

      @media (max-width: 992px) {
        flex: none;
      }
    }
  }
}

.btn-wrap {
  justify-content: flex-end;
}

.auth-btn {
  height: 4.8rem;
  outline: none;
  padding: 0 3.2rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.1rem 0.4rem rgb(0 0 0 / 5%);

  &:hover {
    transition: 0.3s;
  }
}

.sing-in-btn {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  color: #fff;
  background: linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
  border: 0.2rem solid #fff;
  border-radius: 0.8rem;

  &:hover {
    background: #40898c;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 7%);
  }

  &:focus {
    border: 0.2rem solid #5bc0de;
  }
}

.sing-up-btn {
  color: #4ba1a5;
  background-color: #fff;
  border: 0.2rem solid #fff;

  &:hover {
    color: #40898c;
  }

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 5rem;

    & img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &:focus {
    border: 0.2rem solid #5bc0de;
  }
}

.user-head {
  cursor: pointer;
}

.user-icon {
  border-radius: 50%;
  background: #fff;
  padding: 1.6rem;
}

.user-desc {
  background: #fff;
  box-shadow: 0.3rem 0.3rem 1.6rem rgb(112 112 124 / 20%);
  border-radius: 0.8rem;
  min-width: 22rem;
  border: 0.1rem solid #ddd;
  position: absolute;
  z-index: 50;
  top: 5.8rem;
  right: 0;
  overflow: hidden;
}

.user-info {
  border-bottom: 0.1rem solid #e5e5e5;
}

.user-name,
.user-logout-btn {
  padding: 1rem 1.2rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.user-logout-btn {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  transition: 0.3s;

  &:hover {
    background: rgb(0 0 0 / 5%);
    box-shadow: inset 0 0.1rem 0.4rem rgb(0 0 0 / 5%);
    transition: 0.3s;
  }

  img {
    margin-right: 1.6rem;
  }
}

@media (max-width: 768px) {
  .auth-btn {
    width: 4.8rem;
    height: 4.8rem;
  }
}

.mr-lg-3 {
  @media (max-width: 993px) {
    margin-right: 1.6rem;
  }
}

.fullwidth {
  width: 100%;
}
