.header {
  border-bottom: solid 0.1rem #eee;
  padding: 3.2rem;
  font-size: 2.2rem;
  line-height: 2.6rem;
  color: #254e5f;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 4rem;
  right: 4rem;
}
