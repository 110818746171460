.link {
  color: #111;

  &:hover {
    color: #111;
  }
}

.logo {
  max-width: 10rem;
  flex: 1;
  margin-right: 2.4rem;
  width: 35%;
  height: 3.2rem;
  justify-content: center;
  display: flex;

  img {
    object-fit: contain;
  }
}

.title {
  flex: 1;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
}

.shop-name {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #c7c7cc;
  font-family: 'Nunito Sans';
  font-weight: 600;
  margin-top: 0.6rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.main {
  width: 65%;
  flex: 1;
}

.desc {
  margin-top: 0.4rem;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #333333;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  span {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #fff 100%
    );
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5rem;
    height: 2.4rem;
  }
}
