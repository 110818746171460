.card {
  height: 100%;
  background: #fff;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;
  padding-top: 2.4rem;

  &:hover {
    box-shadow: 0 0 2rem #ddd;
    transition: 0.3s;
  }

  & button {
    border: none;
    background: transparent;
    outline: none;
    width: 2rem;
    height: 2rem;
  }
}

.pic {
  margin: 0 auto;
  height: 5rem;
  max-width: 15.5rem;

  img {
    object-fit: contain;
    margin: 0 auto;
  }
}

.link {
  color: #333;
  display: block;
  height: 100%;
  z-index: 2;

  &:hover {
    color: #333;
  }
}

.tag {
  position: absolute;
  bottom: 0.4rem;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
}

.left_divider,
.right_divider {
  width: 0.8rem;
  overflow: hidden;

  div {
    width: 1.6rem;
    height: 1.6rem;
    background-color: #f8f8f8;
    border-radius: 50%;
  }
}

.left_divider {
  margin-right: 0.6rem;
  transform: rotateY(180deg);
}

.middle_divider {
  border-bottom: 6px dotted rgba(0, 0, 0, 0.05);
  width: 100%;
}

.right_divider {
  margin-left: 0.6rem;
}

.bottom {
  margin-top: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #333;
  font-family: 'Nunito Sans';
}

.status {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #c7c7cc;
  text-align: center;
  margin-top: 1.4rem;
  position: relative;
  overflow: hidden;
  padding: 0 2.4rem;

  span {
    background: linear-gradient(
      90deg,
      rgb(255 255 255 / 0%) 0%,
      #fff 100%
    );
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5rem;
    height: 1.6rem;
  }
}

.desc {
  margin-top: 1.6rem;
  position: relative;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;
  color: #333;
  overflow: hidden;
  height: 3.8rem;

  span {
    background: linear-gradient(
      90deg,
      rgb(255 255 255 / 0%) 0%,
      #fff 100%
    );
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5rem;
    height: 1.6rem;
  }
}

.deadline {
  margin-top: 1rem;
  font-family: Roboto;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

.deadline_error {
  color: #d12e34;
}

.deadline_warning {
  color: #dcae1d;
}
