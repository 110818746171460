.btn {
  outline: none;
  border-radius: 0.4rem;
  padding: 1rem 1.8rem;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  margin-top: 1.6rem;
}

.btn-default {
  color: #fff;
  background: #4ba1a5;
  border: 0.1rem solid #4ba1a5;

  &:hover {
    background-color: #254e5f;
    border: 0.1rem solid #254e5f;
    transition: 0.3s;
  }

  &:disabled {
    background-color: #9a9a9a;
    border: 0.1rem solid #9a9a9a;
  }
}

.btn-primary {
  color: #4ba1a5;
  background: #fff;
  border: 0.1rem solid #4ba1a5;
}

.btn-borderless {
  color: #4ba1a5;
  background: #fff;
  border: none;
}

.text {
  white-space: pre-line;
  margin-bottom: 1.6rem !important;
}

.title {
  font-size: 2rem;
  color: #254e5f;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.6rem;
}

.mt-3 {
  margin-top: 1.6rem;
}

.mb-3 {
  margin-bottom: 1.6rem;
}
