.not-found,
.container {
  height: 100%;
}

.not-found {
  text-align: center;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.title {
  color: #254e5f;
  font-weight: 500;
  font-size: 2rem;
}

.link {
  margin: 0 0.4rem;
  text-decoration: underline !important;
}

.mt-1 {
  margin-top: 0.4rem;
}
