.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: linear-gradient(to bottom, #e3f0f0 73%, #fff 50%);
}

.modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3.5rem;
  background: url('../../images/icons/cpnbg.webp') no-repeat 50% 28%;
  color: #254e5f;
}

.text {
  font-size: 80%;
  text-align: center;
}

.promocode-wrapper {
  display: flex;
  min-width: 25rem;
  margin: 2.5rem auto;
  background-color: #fff;
  border-radius: 0.8rem;
}

.promocode {
  width: 100%;
  color: #254e5f;
  padding: 0.9rem 1rem;
  box-shadow: none;
  outline: 0;
  font-weight: bold;
  font-size: 2.3rem;
  border: none;
  background-color: transparent;
  text-align: center;
}

.clipboard-success {
  align-self: center;
  width: 4.5rem;
  height: 4.5rem;
  background: url('../../images/icons/check.webp') no-repeat center;
}

.button {
  margin-top: 0.8rem;
  color: #fff;
  outline: none;
  border-radius: 0.4rem;
  padding: 0.6rem 1.8rem;
  background: #4ba1a5;
  border: 0.1rem solid #4ba1a5;
  transition: 0.3s;
  cursor: pointer;
  font-size: 150%;
  text-align: center;

  &:hover {
    color: #fff;
    background-color: #254e5f;
    border: 0.1rem solid #254e5f;
    box-shadow: -0.1rem 0.6rem 1.3rem rgb(37 78 95 / 40%) !important;
    transition: 0.3s;
  }
}
