.banner {
  background: #fff;
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 15%);
  border-radius: 0 0 0.8rem 0.8rem;
  padding: 1.2rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    margin-right: 1.2rem;

    &:first-child {
      margin-right: 0;
    }
  }
}

.close-btn {
  border: none;
  background: #fff;
  padding: 1.2rem;

  img {
    max-width: 1rem;
  }
}

.banner-logo {
  img {
    max-width: none;
    border-radius: 0.4rem;
  }
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.text {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #333;
}

.link {
  background: #4ba1a5;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #fff;
  border: 0.1rem solid #357d81;
  box-shadow:
    0 0.2rem 0.4rem rgb(52 123 126 / 15%),
    inset 0 0.1rem 0.2rem rgb(255 255 255 / 40%);
  border-radius: 0.8rem;
  text-decoration: none;
  padding: 0.6rem 1.2rem;

  &:hover {
    color: #fff;
  }
}
