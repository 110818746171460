.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.2rem;

  @media (min-width: 768px) {
    padding: 1rem 5rem;
  }
}

.title {
  margin-bottom: 4.8rem;
  font-size: 3.2rem !important;
  font-weight: 700;
}
