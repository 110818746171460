section {
  padding: 1rem 1.2rem;

  @media (min-width: 768px) {
    padding: 1rem 5rem;
  }
}

h2 {
  margin-bottom: 4.8rem;
  font-size: 3.2rem !important;
  font-weight: 700;
}

h3 {
  margin-bottom: 1.6rem;
  font-weight: 700;
  font-size: 2.4rem !important;
}

section section {
  margin-bottom: 1.6rem;
}
