.loader {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 100%;
  max-width: 5.625rem;
  animation: spinner 1s linear infinite;
  z-index: 1000;
}

.loader circle {
  fill: none;
  stroke-width: 8px;
  stroke-dasharray: 300;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
