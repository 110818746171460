.wrap {
  background: #f8f8f8;
  margin-top: -3.2rem;
  min-height: 100vh;
  display: flex;
  height: 100%;
  font-family: 'Nunito Sans', sans-serif;
  padding-bottom: 3.2rem;

  @media (min-width: 768px) {
    padding-bottom: 0;
  }
}

.container {
  display: flex;
  align-items: center;
}

.main {
  overflow: auto;
  text-align: center;
  padding: 3.2rem 0;

  @media (min-width: 768px) {
    display: flex;
    text-align: left;
  }
}

.block {
  margin-bottom: 3.2rem;

  @media (min-width: 768px) {
    margin-right: 10rem;
    margin-bottom: 0;
  }
}

.title {
  font-weight: 600;
  font-size: 4rem;
  line-height: 4.6rem;
  color: #254e5f;
  margin-top: 3.2rem;
  margin-bottom: 0;
}

.subtitle {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  background: linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
  color: transparent;
  background-clip: text;
  margin-top: 1.2rem !important;
}

.links {
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }

  display: flex;

  @media (min-width: 768px) {
    margin-top: 5.6rem;
  }
}

.qrcode {
  @media (min-width: 768px) {
    margin-right: 5.6rem;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.18rem rgba(0, 0, 0, 0.15);
    border-radius: 0.2rem;
    padding: 1.4rem;
  }

  img {
    margin: 0 auto;
  }
}

.text {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #adadb8;
}

.mobile-link {
  background: rgb(255 255 255 / 90%);
  box-shadow: 0 0.1rem 0.6rem rgb(0 0 0 / 7%);
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.mobile-link-wrap {
  padding: 1.1rem;
  background: linear-gradient(#fff, #fff),
    linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
  background-origin: padding-box, border-box;
  background-repeat: no-repeat;
  border: 0.1rem solid transparent;

  a {
    background: linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  &:hover {
    color: #357d81;
    border-color: #357d81;
    transition: 0.3s;
    text-decoration: none;
    box-shadow: 0 0.1rem 0.4rem rgb(0 0 0 / 5%);
    border-radius: 0.2rem;
    display: block;
  }
}

.badges {
  margin-top: 2.8rem;
  display: flex;
  justify-content: center;

  a {
    margin-right: 1.6rem;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    height: 4.8rem;

    @media (min-width: 768px) {
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.link {
  display: block;
  margin-top: 3.2rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background: linear-gradient(45deg, #3c9f83 0%, #4ba1a5 100%);
  color: transparent;
  background-clip: text;
  transition: 0.3s;
  border-bottom: 0.1rem solid #3c9f83;
  width: fit-content;

  &:hover {
    color: #357d81;
    border-color: #357d81;
    transition: 0.3s;
    text-decoration: none;
  }
}

.fullwidth {
  img {
    width: 24rem;
  }
}

.pic {
  @media (max-width: 768px) {
    height: auto;
  }
}
