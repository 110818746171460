.wrap {
  margin-top: 7rem;
}

.desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  margin-top: 1.6rem;
}

.title {
  color: #254e5f;
  font-family: 'Nunito Sans';
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  text-align: center;
}

.subtitle {
  color: rgb(51, 51, 51);
  font-family: 'Roboto';
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}

.btn {
  margin-top: 4.8rem;
}

.mobile_btn {
  margin-bottom: 1.6rem;
}
