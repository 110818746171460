@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NunitoSans-400.woff2') format('woff2');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/NunitoSans-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/NunitoSans-700.woff2') format('woff2');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-400.woff2') format('woff2');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-500.woff2') format('woff2');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Roboto-700.woff2') format('woff2');
}
