.forgot-pass {
  background: #4ba1a5;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.container,
.main {
  height: 100%;
}

.main {
  max-width: 72rem;
  margin: 0 auto;
}

.label {
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.block {
  background: #fff;
  border-radius: 1rem;
  padding: 1.2rem;
  box-shadow: 0 0.1rem 0.1rem 0 rgb(0 0 0 / 10%);
  width: 100%;
}

.input {
  border-radius: 0.4rem;
  color: #444;
  background-color: #fff !important;
  border: 0.1rem solid #ccc;
  font-weight: 400;
  width: 100%;
  padding: 1.2rem 1rem;
  height: auto;
  font-size: 1.4rem;
  max-width: 100%;
}

.btn {
  margin-top: 0.8rem;
  color: #fff;
  outline: none;
  border-radius: 0.4rem;
  padding: 1rem 1.8rem;
  background: #4ba1a5;
  border: 0.1rem solid #4ba1a5;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #254e5f;
    border: 0.1rem solid #254e5f;
    transition: 0.3s;
  }
}

.m-auto-md-0 {
  margin: auto;
  @media (min-width: 768px) {
    margin: 0;
  }
}

.mt-3 {
  margin-top: 1.6rem;
}

.mb-3 {
  margin-bottom: 1.6rem;
}

.mt-4 {
  margin-top: 2.4rem;
}
