.shop_info {
  padding: 3.2rem;
  border-radius: 0.8rem;
  background: var(--grey-base-white, #fff);
  box-shadow: 0.3rem 0.3rem 0.8rem 0 rgba(112, 112, 124, 0.1);
}

.shop_info_head {
  display: flex;
  align-items: center;
}

.pic {
  margin-right: 1.2rem;
  height: 5rem;
}

.shop_info_desc {
  margin-top: 2.4rem;
  font-family: Roboto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 125% */
}

.shop-name {
  font-family: 'Nunito Sans';
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  color: #333;
  margin-bottom: 0.8rem;
}

.category {
  font-family: 'Nunito Sans';
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  color: #adadb8;
  margin-top: 0.4rem;
}

.list {
  margin: 0 -0.6rem !important;
  padding: 0 !important;

  @media (min-width: 768px) {
    margin: 0 -1.2rem !important;
  }
}

.item {
  width: calc(100% / 1 - 2.4rem);
  margin: 0 1.2rem;
  background: #fff;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;
  transition: 0.3s;
  margin-bottom: 2.4rem;

  &:hover {
    box-shadow: 0 0 2rem #ddd;
    transition: 0.3s;
  }

  @media (min-width: 768px) {
    width: calc(100% / 2 - 2.4rem);
  }

  @media (min-width: 992px) {
    width: calc(100% / 3 - 2.4rem);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 4 - 2.4rem);
  }
}

.title {
  font-size: 2rem;
  color: #254e5f;
  margin-bottom: 2.4rem;
  font-weight: 700;
  font-family: 'Nunito Sans';
  line-height: 2.4rem;
  margin-top: 3.2rem;
}

.mt-4 {
  margin-top: 2.4rem;
}
