.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  @media (min-width: 768px) {
    margin-top: 11.2rem;
  }
}

.indent {
  margin-top: 5.2rem;

  @media (max-width: 350px) {
    margin-top: 7rem;
  }

  @media (max-width: 333px) {
    margin-top: 8.6rem;
  }
}

.app_btn {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 4.8rem;
    margin-right: -2.5rem;
    opacity: 0.6;
    z-index: 10;
  }

  @media (min-width: 1600px) {
    margin-right: -10rem;
  }
}
