.modal {
  height: 45%;
}

.text {
  white-space: pre-line;
}

.title {
  font-size: 2rem;
  color: #254e5f;
  font-weight: 700;
  text-align: center;
}

.reg-link {
  font-size: 1.4rem;
  border-top: 0.1rem solid #ddd;
  margin-top: 1.6rem;
  padding-top: 1.6rem;
}

.footnote {
  font-size: 1.2rem;
  margin-top: 2.4rem;

  a {
    color: #4ba1a5;
    transition: 0.3s;

    &:hover {
      color: #111;
      transition: 0.3s;
    }
  }
}

.switcher {
  margin-bottom: 3.2rem;
}
