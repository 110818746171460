.base-placeholder {
  position: relative;
  z-index: 9;
  padding: 0.6rem 0;
  background: linear-gradient(
    100deg,
    #f8f8f8 30%,
    #fdfdfd 50%,
    #f8f8f8 70%
  );
  background-size: 400%;
  border-radius: 6px;
  animation: loading 1.2s ease-in-out infinite;
}

.placeholder-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.placeholder-row {
  height: 3.75rem;
  margin-top: 1.5rem;
}

.placeholder-title {
  background: linear-gradient(
    100deg,
    #f8f8f8 30%,
    #fdfdfd 50%,
    #f8f8f8 70%
  );
  max-width: 15.625rem;
  height: 2rem;
  background-size: 250%;
  margin: 0 auto;
  margin-bottom: 1.6rem;
}

.paragraph-placeholder {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2.4rem;
}

.placeholder-product-list {
  display: flex;
  flex-flow: row wrap;
  margin-top: 1.714rem;
  justify-content: space-between;
}

.placeholder-product-list .image-medium {
  height: 5rem;
  margin: 0 auto;
  max-width: 15.5rem;
  margin-bottom: 1.6rem;
}

.placeholder-product-item {
  width: 100%;
  margin-bottom: 5.143rem;
  background-color: #fff;
  padding-top: 2.4rem;
  border-radius: 0.4rem;
  box-shadow: 0.3rem 0.3rem 0.8rem #70707c1a;
  height: 32.2rem;

  @media (min-width: 768px) {
    width: calc(100% / 2 - 3.2rem);
  }

  @media (min-width: 992px) {
    width: calc(100% / 3 - 3.2rem);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 4 - 3.2rem);
  }
}

.image-big {
  width: 304px;
  height: 324px;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
  margin-bottom: 1.6rem;
}

.left_divider,
.right_divider {
  width: 0.8rem;
  overflow: hidden;

  div {
    width: 1.6rem;
    height: 1.6rem;
    background-color: #f8f8f8;
    border-radius: 50%;
  }
}

.left_divider {
  margin-right: 0.6rem;
  transform: rotateY(180deg);
}

.middle_divider {
  border-bottom: 6px dotted rgba(0, 0, 0, 0.05);
  width: 100%;
}

.right_divider {
  margin-left: 0.6rem;
}

.coupon-list-placeholder {
  display: flex;
  padding-left: 0;
  position: relative;
  justify-content: flex-start;
  overflow: auto;
  margin-bottom: -4.8rem;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    overflow: visible;
    justify-content: center;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.coupon-item-placeholder {
  padding: 0.8rem 2rem 0.7rem;
  border: 0.1rem solid #eee;
  border-radius: 4.8rem;
  background-color: #fefefe;
  box-shadow:
    0 0.3rem 0.8rem rgba(0, 0, 0, 0.12),
    0 0.3rem 0.1rem rgba(0, 0, 0, 0.04);
  margin: 0.5rem 1rem;
  width: 15rem;
  min-width: 15rem;
  height: 3.3rem;
}

.coupon-text-placeholder {
  z-index: 9;
  padding: 0.6rem 0;
  background: linear-gradient(
    100deg,
    #f8f8f8 30%,
    #fdfdfd 50%,
    #f8f8f8 70%
  );
  background-size: 400%;
  border-radius: 6px;
  animation: loading 1.2s ease-in-out infinite;
  height: 1.6rem;
}

.preload-page-main {
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
  }
}

.preload-card-wrapper {
  @media (min-width: 768px) {
    margin-right: 3.2rem;
  }
}

.preload-card-img-wrap {
  padding: 0 0.8rem;
  width: 35rem;
}

.preload-card-img {
  width: 100%;
  margin: 0 auto;
  height: 12rem;
}

.preload-card-desc {
  background: #fff;
  padding: 2.4rem 0;
  box-shadow: 0.3rem 0.3rem 0.8rem rgba(112, 112, 124, 0.1);
  border-radius: 0.4rem;

  @media (min-width: 768px) {
    width: 35.7rem;
  }
}

.preload-desc-wrapper {
  width: 100%;
  background: #fff;
  padding: 2.4rem;
  margin-top: 2rem;
  box-shadow: 0.3rem 0.3rem 0.8rem rgb(112 112 124 / 10%);
  border-radius: 0.4rem;
  margin-bottom: 3.2rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.card-paragraph-placeholder {
  height: 8rem;
  width: 100%;
  margin-bottom: 1.6rem;
}

.card-title-placeholder {
  height: 2.4rem;
  width: 100%;
  margin-bottom: 1.6rem;
}

.card-separator-placeholder {
  background: #0000000d;
  border-radius: 0.8rem;
  height: 0.2rem;
  margin: 2.4rem 0;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
