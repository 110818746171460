.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #333;
  text-align: center;
}

.mt-5 {
  margin-top: 4.8rem;
}

.mt-3 {
  margin-top: 1.6rem;
}
