.list {
  margin: 0 -0.6rem !important;
  padding: 0 !important;

  @media (min-width: 768px) {
    margin: 0 -1.6rem !important;
  }
}

.item {
  width: calc(100% / 1 - 3.2rem);
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  transition: 0.3s;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    width: calc(100% / 2 - 3.2rem);
  }

  @media (min-width: 992px) {
    width: calc(100% / 3 - 3.2rem);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 4 - 3.2rem);
  }
}

.mt-5 {
  margin-top: 4.8rem;
}

.btn {
  padding: 0 0.8rem;
  z-index: 1;
  margin-top: -0.4rem;
}
